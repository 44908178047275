import React, { useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import { Dialog } from "primereact/dialog";
import TPassword from "../../../../Healer/TPassword/TPassword";
const backend = new Backend();

export default function AccountCard(props) {
  const i = props.item;

  const [show_update, setUpdate] = useState(false);
  const [tpassword, setTPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const Update = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      account_id: i.id,
      tpassword: tpassword,
    };
    backend.active_account(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.load();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        height: 50,
        display: "flex",
        backgroundColor: Dark.background,
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.account_name}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {i.account_number}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.account_ifsc}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.bank}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.type}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.upi_id}
      </div>
      <div
        onClick={() => setUpdate(true)}
        style={{
          color: i.status == 1 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 14,
        }}
      >
        {i.status == 1 ? "Active" : "Deactive"}
      </div>

      <Dialog
        visible={show_update}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setUpdate(false)}
      >
        <TPassword Update={Update} tpassword={(a) => setTPassword(a)} />
      </Dialog>
    </div>
  );
}

import React from "react";
import { Dark } from "../../../../Theme/Theme";
import { NumericFormat } from "react-number-format";
export default function SummaryCard(props) {
  const item = props.item;

  let profit_loss_week =
    item.profit_loss_week == undefined
      ? 0
      : parseFloat(item.profit_loss_week).toFixed(0);

  let brokerage_week =
    item.brokerage_week == undefined
      ? 0
      : parseFloat(item.brokerage_week).toFixed(0);

  let brokerage_broker_week_fut =
    item.brokerage_broker_week_fut == undefined
      ? 0
      : parseFloat(item.brokerage_broker_week_fut).toFixed(0);

  let brokerage_broker_week_mcx =
    item.brokerage_broker_week_mcx == undefined
      ? 0
      : parseFloat(item.brokerage_broker_week_mcx).toFixed(0);

  let brokerage_broker_week_options =
    item.brokerage_broker_week_options == undefined
      ? 0
      : parseFloat(item.brokerage_broker_week_options).toFixed(0);

  let brokerage_broker_week =
    item.brokerage_broker_week == undefined
      ? 0
      : parseFloat(item.brokerage_broker_week).toFixed(0);

  return (
    <div
      style={{
        height: "55px",
        width: "100%",
        backgroundColor: Dark.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 12,
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        {item.broker_name}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: item.profit_loss_week > 0 ? Dark.buy : Dark.sell,
        }}
      >
        <NumericFormat
          value={profit_loss_week}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.sell,
        }}
      >
        <NumericFormat
          value={brokerage_week}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        <NumericFormat
          value={brokerage_broker_week_fut}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        <NumericFormat
          value={brokerage_broker_week_mcx}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        <NumericFormat
          value={brokerage_broker_week_options}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        <NumericFormat
          value={brokerage_broker_week}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>
    </div>
  );
}

/* eslint-disable react/prop-types */
import { Font, Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import { Dark } from "../Theme/Theme";
Font.register({
  family: "Times-Bold",
  fontWeight: "bold",
});

const styles = StyleSheet.create({
  Family: {
    fontFamily: "Times-Bold",
  },
  container: {
    width: "100%",
    padding: "2px",
    marginTop: "8px",
    borderBottom: "2px",
    borderColor: "black",
    paddingBottom: "5px",
  },
  heading: {
    width: "42%",
    backgroundColor: "#C6C6C5",
    fontSize: "10px",
    marginBottom: "2px",
    padding: "3px",
  },
  SecondView: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "10px",
    alignItems: "baseline",
  },
  SecondViewLeftParent: {
    width: "53%",
    flexDirection: "column",
    justifyContent: "center",
    gap: "6px",
  },
  SecondViewLeft: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: "9px",
  },
  SecondViewRightParent: {
    width: "46%",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "flex-end",
    gap: "6px",
  },
  SecondViewRight: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: "9px",
  },
  TotalViewParent: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
    borderTop: "1px",
    borderBottom: "2px",
    borderColor: "black",
    padding: "2px",
    textAlign: "left",
  },
  TotalViewLeft: {
    width: "51%",
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: "9px",
    textAlign: "left",
  },
  TotalViewRight: {
    width: "46%",
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: "9px",
  },
  LastViewParent: {
    width: "90%",
    marginTop: "8px",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  LastVeiwWrapper: {
    width: "35%",
    flexDirection: "column",
    gap: "8px",
  },

  resultView: {
    width: "95%",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "9px",
  },
});

// ------ JSX SECTION ---------
const TestingPage = (props) => {
  let data = props.data;


  let gross = parseFloat(
    JSON.stringify(parseFloat(data.profit_loss) + parseFloat(data.brokerage))
  ).toFixed(0);

  let newgross = parseFloat(gross > 0 ? gross : gross.replace("-", "")).toFixed(
    0
  );

  let sauda = parseFloat(JSON.stringify(parseFloat(data.profit_loss))).toFixed(
    0
  );

  let netsauda = parseFloat(sauda > 0 ? sauda : sauda.replace("-", "")).toFixed(
    0
  );

  return (
    <>
      {/* <View
        style={{
          backgroundColor: "#C6C6C5",
          width: "100%",
          padding: "4px",
          textAlign: "center",
          marginTop: "-15px",
        }}
      >
        <Text style={[styles.Family, { fontSize: "8px" }]}>
          {data.script_type == "fut"
            ? "NSE"
            : data.script_type == "mcx"
            ? "MCX"
            : "FO"}
          NSE
        </Text>
      </View> */}

      <View style={styles.container}>
        {/* ----- heading ------ */}
        <View style={styles.heading}>
          <Text style={[styles.Family]}>
            {data.symbol} {moment(data.expiry_date).format("DD-MMM-YYYY")}
          </Text>
        </View>
        {/* second View */}
        <View style={styles.SecondView}>
          {/* ---- left Purchase section  -----*/}
          <View style={styles.SecondViewLeftParent}>
            {data.openingbuy == null ? null : (
              <View style={styles.SecondViewLeft}>
                <Text style={[styles.Family, { width: "17%" }]}>
                  {"Opening"}
                </Text>
                <Text style={{ width: "17%" }}>{data.openingbuy.qty}</Text>
                <Text style={{ width: "17%" }}>{data.openingbuy.rate}</Text>
                <Text style={[styles.Family, { width: "17%" }]}>
                  {parseFloat(data.openingbuy.amount).toFixed(0)}
                </Text>
              </View>
            )}

            {data.buytrade.length > 0
              ? data.buytrade.map((item, index) => {
                  return (
                    <View key={index} style={styles.SecondViewLeft}>
                      <Text style={[styles.Family, { width: "17%" }]}>
                        {item.type == "Weekly Sq Off"
                          ? "Closing"
                          : moment(item.date_created).format("DD-MM-YYYY")}
                      </Text>
                      <Text style={{ width: "17%" }}>{item?.qty}</Text>
                      <Text style={{ width: "17%" }}>{item?.rate}</Text>
                      <Text style={[styles.Family, { width: "17%" }]}>
                        {parseFloat(item?.amount).toFixed(0)}
                      </Text>
                    </View>
                  );
                })
              : null}
          </View>
          {/* ------ right Sale section --- */}
          <View style={styles.SecondViewRightParent}>
            {data.openingsell == null ? null : (
              <View style={styles.SecondViewLeft}>
                <Text style={[styles.Family, { width: "17%" }]}>
                  {"Opening"}
                </Text>
                <Text style={{ width: "17%" }}>{data.openingsell.qty}</Text>
                <Text style={{ width: "17%" }}>{data.openingsell.rate}</Text>
                <Text style={[styles.Family, { width: "17%" }]}>
                  {parseFloat(data.openingsell.amount).toFixed(0)}
                </Text>
              </View>
            )}

            {data.selltrade.length > 0
              ? data.selltrade.map((data, index) => (
                  <View key={index} style={styles.SecondViewRight}>
                    <Text style={[styles.Family, { width: "17%" }]}>
                      {data.type == "Weekly Sq Off"
                        ? "Closing"
                        : moment(data.date_created).format("DD-MM-YYYY")}
                    </Text>
                    <Text style={{ width: "15%" }}>{data?.qty}</Text>
                    <Text style={{ width: "15%" }}>{data?.rate}</Text>
                    <Text style={{ width: "15%" }}>
                      {parseFloat(data?.amount).toFixed(0)}
                    </Text>
                  </View>
                ))
              : null}
          </View>
        </View>

        {/* ----- Total View tag opening ----- */}
        <View style={styles.TotalViewParent}>
          {/* leftOne View  */}
          <View style={styles.TotalViewLeft}>
            <Text style={{ width: "14%" }}>
              {"TOTAL"}
              {/* below is the dummy Text tag just for indentation */}
              <Text style={{ color: "white", width: "14%" }}></Text>
            </Text>
            <Text style={{ width: "14%" }}>{data.buy_qty}</Text>
            <Text style={{ color: "white", width: "14%" }}> </Text>
            <Text style={{ width: "14%" }}>
              {parseFloat(data.buy_amount).toFixed(0)}{" "}
            </Text>
          </View>
          {/* RightOne View  */}
          <View style={styles.TotalViewRight}>
            <Text style={{ color: "white", width: "14%" }}>{"TOTAL"}</Text>
            <Text style={{ width: "14%" }}>{data.sell_qty}</Text>
            <Text style={{ color: "white", width: "14%" }}> </Text>
            <Text style={{ width: "14%" }}>
              {parseFloat(data.sell_amount).toFixed(0)}{" "}
            </Text>
          </View>
          {/* -----Below is total View closing tag ----- */}
        </View>

        {/* ----- below is the Last View Tag opening ---- */}
        <View style={styles.LastViewParent}>
          {/* nested View tag */}
          <View style={styles.LastVeiwWrapper}>
            <View style={styles.resultView}>
              <Text>Gross Amount</Text>
              <Text
                style={[
                  styles.boldText,
                  {
                    color:
                      parseFloat(data.profit_loss + data.brokerage) > 0
                        ? Dark.primary
                        : Dark.sell,
                  },
                ]}
              >
                {newgross}{" "}
                {parseFloat(
                  parseFloat(data.profit_loss) + parseFloat(data.brokerage)
                ) > 0
                  ? "Cr"
                  : "Dr"}
              </Text>
            </View>

            <View style={styles.resultView}>
              <Text style={styles.boldText}>Brokerage</Text>
              <Text style={{ color: Dark.sell, fontWeight: "bold" }}>
                {parseFloat(data.brokerage).toFixed(0)} {"Dr"}
              </Text>
            </View>

            <View style={[styles.resultView, styles.Family]}>
              <Text>Sauda Net Amt</Text>
              <Text
                style={{
                  color: data.profit_loss > 0 ? Dark.primary : Dark.sell,
                }}
              >
                {parseFloat(netsauda).toFixed(0)}{" "}
                {parseFloat(data.profit_loss) > 0 ? "Cr" : "Dr"}
              </Text>
            </View>
          </View>

          {/* ---- below is the Last View closing Tag -------  */}
        </View>

        {/* ----- below is the container closing View Tag ----- */}
      </View>
    </>
  );
};

export default TestingPage;

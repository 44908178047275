import React, { useState } from "react";
import { Dark } from "../../../../../Theme/Theme";

export default function BrokerCard(props) {
  const item = props.item;
  return (
    <div
      onClick={() => props.update(item.id)}
      style={{
        width: "90%",
        padding: 13,
        backgroundColor: Dark.background,
        borderRadius: 10,
        color: Dark.text,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor:
          props.broker_id == item.id ? Dark.primary : Dark.background,
        marginBottom: 5,
        marginTop: 5,
      }}
    >
      {item.broker_name}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import { Dark } from "../../../../Theme/Theme";
import moment from "moment";
const backend = new Backend();

export default function Profile() {
  const [profile, setProfile] = useState("");

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      username: localStorage.getItem("username"),
    };

    backend.profile(data).then((r) => {
      if (r.error == "False") {
        setProfile(r.profile);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        marginRight: 10,
        marginLeft: 10,
      }}
    >
      <div
        style={{
          padding: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
          fontSize: 12,
        }}
      >
        ESC TO CLOSE
      </div>

      <div
        style={{
          padding: 10,
          backgroundColor: Dark.background,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          SERVER
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          {profile.server}
        </div>
      </div>

      <div
        style={{
          padding: 10,
          backgroundColor: Dark.background,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          USERNAME
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          {profile.username}
        </div>
      </div>

      <div
        style={{
          padding: 10,
          backgroundColor: Dark.background,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          NAME
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          {profile.name}
        </div>
      </div>

      <div
        style={{
          padding: 10,
          backgroundColor: Dark.background,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          FUTURE ({profile.future_min_volume})
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            color: profile.future == 1 ? Dark.buy : Dark.sell,
          }}
        >
          {profile.future == 1 ? "ACTIVE" : "DEACTIVE"}
        </div>
      </div>

      <div
        style={{
          padding: 10,
          backgroundColor: Dark.background,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          MCX ({profile.mcx_min_lot}) (
          {profile.lot_volume == 0 ? "LOT" : "VOLUME"})
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            color: profile.mcx == 1 ? Dark.buy : Dark.sell,
          }}
        >
          {profile.mcx == 1 ? "ACTIVE" : "DEACTIVE"}
        </div>
      </div>

      <div
        style={{
          padding: 10,
          backgroundColor: Dark.background,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          OPTIONS ({profile.options_min_lot}) (LOT)
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            color: profile.options == 1 ? Dark.buy : Dark.sell,
          }}
        >
          {profile.options == 1 ? "ACTIVE" : "DEACTIVE"}
        </div>
      </div>

      <div
        style={{
          padding: 10,
          backgroundColor: Dark.background,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          ACCOUNT START DATE
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          {moment(profile.date_created).format("DD-MM-YYYY")}
        </div>
      </div>
    </div>
  );
}

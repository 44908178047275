import React, { useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { Dialog } from "primereact/dialog";
import AddAccount from "./AddAccount/AddAccount";
export default function TopCard() {
  const [accont, setAccount] = useState(false);
  return (
    <div
      style={{
        height: 55,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        color: Dark.text,
        paddingRight: 40,
      }}
    >
      <div
        // onClick={() => setAccount(true)}
        onClick={() => alert("Cooming Soon")}
        style={{
          padding: 12,
          backgroundColor: Dark.primary,
          paddingRight: 30,
          paddingLeft: 30,
          borderRadius: 10,
        }}
      >
        ADD ACCOUNT
      </div>

      <Dialog
        header={"Add Bank Account"}
        visible={accont}
        style={{ width: "40vw", height: "60vh" }}
        onHide={() => setAccount(false)}
      >
        <AddAccount />
      </Dialog>
    </div>
  );
}

import React, { useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import moment from "moment";
import "../PayIn.css";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "react-loading";
import { Dialog } from "primereact/dialog";
import TPassword from "../../../../../Healer/TPassword/TPassword";
const backend = new Backend();

export default function PayInCard(props) {
  const i = props.item;

  const [loading, setLoading] = useState(false);
  const [show_pass, setPass] = useState(false);
  const [show_cancel, setCancel] = useState(false);
  const [tpassword, setTPassword] = useState("");

  const Success = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: i.client_id,
      payment_id: i.id,
      tpassword: tpassword,
    };
    backend.pass_payin(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.load();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const Cancel = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: i.client_id,
      payment_id: i.id,
      tpassword: tpassword,
    };
    backend.cancel_payin(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.load();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        height: 50,
        display: "flex",
        backgroundColor: Dark.background,
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
        textTransform: "uppercase",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        {i.client_username}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 14 }}>{i.payment_id}</div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 14 }}>{i.amount}</div>
      </div>
      <div
        style={{
          color:
            i.status == 0 ? Dark.text : i.status == 1 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 14,
        }}
      >
        {i.status == 0 ? "WAITING FOR PAYMENT" : i.msg}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 14,
        }}
      >
        {moment(i.date_created).format("DD-MM-YYYY HH:mm:ss")}
      </div>
      <div
        onClick={() => (i.status == 0 ? setPass(true) : null)}
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 14,
          opacity: i.status == 0 ? 1 : 0.1,
        }}
      >
        <div
          style={{
            height: 37,
            width: 100,
            backgroundColor: Dark.buy,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <Loading height={20} width={20} color={Dark.text} type="spin" />
          ) : (
            "SUCCESS"
          )}
        </div>
      </div>
      <div
        onClick={() => (i.status == 0 ? setCancel(true) : null)}
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 14,
          opacity: i.status == 0 ? 1 : 0.1,
        }}
      >
        <div
          style={{
            height: 37,
            width: 100,
            backgroundColor: Dark.sell,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <Loading height={20} width={20} color={Dark.text} type="spin" />
          ) : (
            "CANCEL"
          )}
        </div>
      </div>

      <Dialog
        visible={show_pass}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setPass(false)}
      >
        <TPassword Update={Success} tpassword={(a) => setTPassword(a)} />
      </Dialog>
      <Dialog
        visible={show_cancel}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setCancel(false)}
      >
        <TPassword Update={Cancel} tpassword={(a) => setTPassword(a)} />
      </Dialog>
    </div>
  );
}

import React from "react";
import { Dark } from "../../../../../Theme/Theme";
import PosistionCard from "./PositionCard";

export default function ClientStanding(props) {
  return (
    <div>
      <div
        style={{
          height: 30,
          display: "flex",
          backgroundColor: Dark.background,
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
          borderRadius: 10,
        }}
      >
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          SYMBOL
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          B/S
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          QTY
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          RATE
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            fontSize: 12,
          }}
        >
          LIVERATE
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          M2M
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          BROKERAGE
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            fontSize: 12,
          }}
        >
          P/L
        </div>
      </div>
      {props.client.length > 0
        ? props.client.map((i) => {
            return <PosistionCard item={i} />;
          })
        : null}
    </div>
  );
}

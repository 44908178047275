import React from "react";
import { Dark } from "../../../../Theme/Theme";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

export default function Menu(props) {
  return (
    <div
      style={{
        height: "35px",
        width: "100%",
        backgroundColor: Dark.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 12,
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>CLIENT</div>
        <div onClick={() => props.sort("ClientUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("ClientDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>TODAY</div>
        <div onClick={() => props.sort("TodayUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("TodayDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>WEEK</div>
        <div onClick={() => props.sort("WeekUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("WeekDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div> NET BALANCE</div>
        <div onClick={() => props.sort("NetUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("NetDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>CREDIT</div>
        <div onClick={() => props.sort("CreditUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("CreditDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        STANDING
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        LIVE P/L
      </div>
  
    </div>
  );
}

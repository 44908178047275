import React from "react";
import { Dark } from "../../../../../Theme/Theme";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Pdf from "./Pdf/Pdf";
export default function Menu(props) {
  return (
    <div
      style={{
        height: 40,
        width: "100%",
        backgroundColor:Dark.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        CLIENT
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        FUTURE
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        MCX
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        OPTIONS
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        FUTURE-B
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        MCX-B
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        OPTIONS-B
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        SHARING-B
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        DATE
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          backgroundColor: Dark.background,
          borderRadius: 10,
          height: 32,
          marginRight:5
        }}
      >
        <PDFDownloadLink
          style={{
            textDecoration: "none",
          }}
          document={<Pdf data={props.client} />}
          fileName={`${props.username}-Broker-Client`}
        >
          {({ blob, url, loading, error }) => (
            <div style={{ color: Dark.text }}>PDF</div>
          )}
        </PDFDownloadLink>
      </div>
    </div>
  );
}

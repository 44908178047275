import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import { InputText } from "primereact/inputtext";
import "./Edit.css";
import Loading from "react-loading";
import Active from "../Active/Active";
import Future from "../Active/Future";
import Mcx from "../Active/Mcx";
import Options from "../Active/Options";

import LotVolume from "../Active/LotVolume";

import SymbolBrokerage from "../SymbolBrokerage/SymbolBrokerage";

import FreshLimit from "../Active/FreshLimit";
import AutoSq from "../Active/AutoSq";

import MidLimit from "../Active/MidLimit";
import OnlySq from "../Active/OnlySq";

import Intraday from "../Active/Intraday";
import MaxQty from "../Active/MaxQty";

import Notification from "../Active/Notification";
import Watch from "../Active/Watch";
import TPassword from "../../../../../Healer/TPassword/TPassword";
import { Dialog } from "primereact/dialog";
import { ToWords } from "to-words";
import { NumericFormat } from "react-number-format";

const toWords = new ToWords({
  localeCode: "hi-IN",
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: "₹",
      plural: "₹",
      symbol: "₹",
      fractionalUnit: {
        name: "Paisa",
        plural: "Paise",
        symbol: "",
      },
    },
  },
});

const backend = new Backend();

export default function Edit(props) {
  const client = props.client;

  const [loading, setLoading] = useState(false);

  const [show_edit_client_details, setshow_edit_client_details] =
    useState(false);
  const [show_edit_master_brokerage, setshow_edit_master_brokerage] =
    useState(false);

  const [brokerage, setbrokerage] = useState(false);

  const [clientS, setClientS] = useState("");
  const [masterS, setMasterS] = useState("");
  const [name, setName] = useState("");

  const [fund, setfund] = useState("");
  const [password, setPassword] = useState("");
  const [tpassword, setTPassword] = useState("");

  const [future_brokerage, setfuture_brokerage] = useState("");
  const [mcx_brokerage, setmcx_brokerage] = useState("");
  const [options_brokerage, setoptions_brokerage] = useState("");

  useEffect(() => {
    loadClientSingle();
    loadMaster();
  }, []);

  const loadClientSingle = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: client.id,
    };

    backend.single_client(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setClientS(r.client);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadMaster = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.load_master(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setMasterS(r.master);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const edit_client_details = () => {
    setLoading(true);
    setshow_edit_client_details(false);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: clientS.id,
      name: name == "" ? clientS.name : name,
      fund: fund,
      password: password,
      tpassword: tpassword,
    };

    backend.edit_client_details(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        loadClientSingle();
        props.reload();
        setName("");
        setPassword("");
        setfund("");
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const edit_master_brokerage = () => {
    setshow_edit_master_brokerage(false);
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: clientS.id,
      future_brokerage:
        future_brokerage == "" ? clientS.future_brokerage : future_brokerage,
      mcx_brokerage:
        mcx_brokerage == "" ? clientS.mcx_brokerage : mcx_brokerage,
      options_brokerage:
        options_brokerage == "" ? clientS.options_brokerage : options_brokerage,
      tpassword: tpassword,
    };

    backend.edit_client_brokerage(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        setfuture_brokerage("");
        setmcx_brokerage("");
        setoptions_brokerage("");
        loadClientSingle();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "90%",
      }}
    >
      <div
        key={clientS}
        style={{
          flex: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            color: Dark.text,
            padding: 10,
            textTransform: "uppercase",
            backgroundColor: Dark.primary,
            display: "flex",
          }}
        >
          <div style={{ flex: 1 }}>Client details</div>
          <div style={{ textTransform: "uppercase" }}>
            {" "}
            opening balance :{" "}
            <NumericFormat
              value={clientS.deposit_total}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            ₹
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>{clientS.name}</div>
            <div className="loginInputEditAdmin">
              <InputText
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Name"
              />
            </div>
          </div>

          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>
              <NumericFormat
                value={clientS.deposit_current}
                displayType={"text"}
                thousandSeparator={true}
              />{" "}
              ₹
            </div>
            <div className="loginInputEditAdmin">
              <InputText
                value={fund}
                onChange={(e) => {
                  setfund(e.target.value);
                }}
                placeholder="Fund - & +"
              />
            </div>
            <div style={{ color: Dark.text, marginTop: 5 }}>
              {fund == "" ? null : fund > 0 ? toWords.convert(fund) : null }
            </div>
          </div>

          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>PASSWORD</div>
            <div className="loginInputEditAdmin">
              <InputText
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="Password"
              />
            </div>
          </div>
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              onClick={() =>
                loading ? null : setshow_edit_client_details(true)
              }
              style={{
                color: Dark.text,
                height: 40,
                width: 100,
                backgroundColor: Dark.primary,
                paddingLeft: 25,
                paddingRight: 25,
                borderRadius: 7,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading ? (
                <Loading type="spin" height={25} width={25} color={Dark.text} />
              ) : (
                "SAVE"
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            color: Dark.text,
            padding: 10,
            textTransform: "uppercase",
            backgroundColor: Dark.primary,
            display: "flex",
          }}
        >
          <div style={{ flex: 1 }}>client brokerage</div>
          <div>
            brokerage minimum ( FUTURE : {masterS.future_min_volume} ) ( MCX - V
            : {masterS.mcx_min_volume} L : {masterS.mcx_min_lot} ) ( OPTIONS :{" "}
            {masterS.options_min_lot} )
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text }}>FUTURE</div>
            <div className="loginInputEditAdmin">
              <div style={{ textAlign: "center", marginTop: 5 }}>
                {clientS.future_brokerage}
              </div>
              <InputText
                value={future_brokerage}
                onChange={(e) => {
                  setfuture_brokerage(e.target.value);
                }}
                type="number"
                placeholder="Enter Brokerage"
              />
            </div>
          </div>
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>MCX</div>
            <div className="loginInputEditAdmin">
              <div style={{ textAlign: "center", marginTop: 5 }}>
                {clientS.lot_volume == 0 ? "L" : "V"} {clientS.mcx_brokerage}
              </div>
              <InputText
                value={mcx_brokerage}
                onChange={(e) => {
                  setmcx_brokerage(e.target.value);
                }}
                placeholder="Enter Brokerage"
              />
            </div>
          </div>
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>OPTIONS</div>
            <div className="loginInputEditAdmin">
              <div style={{ textAlign: "center", marginTop: 5 }}>
                {clientS.options_brokerage}
              </div>
              <InputText
                value={options_brokerage}
                onChange={(e) => {
                  setoptions_brokerage(e.target.value);
                }}
                placeholder="Enter Brokerage"
              />
            </div>
          </div>
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              onClick={() =>
                loading ? null : setshow_edit_master_brokerage(true)
              }
              style={{
                color: Dark.text,
                height: 40,
                width: 100,
                backgroundColor: Dark.primary,
                paddingLeft: 25,
                paddingRight: 25,
                borderRadius: 7,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading ? (
                <Loading type="spin" height={25} width={25} color={Dark.text} />
              ) : (
                "SAVE"
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 0.5,
          height: "55px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <LotVolume
          status={clientS.lot_volume}
          client_id={clientS.id}
          reload={loadClientSingle}
        />
        <Active
          status={clientS.status}
          client_id={clientS.id}
          reload={loadClientSingle}
        />
        <Future
          status={clientS.future}
          client_id={clientS.id}
          reload={loadClientSingle}
        />
        <Mcx
          status={clientS.mcx}
          client_id={clientS.id}
          reload={loadClientSingle}
        />
        <Options
          status={clientS.options}
          client_id={clientS.id}
          reload={loadClientSingle}
        />
        <Notification
          status={clientS.master_notification}
          client_id={clientS.id}
          reload={loadClientSingle}
        />
        <Watch
          status={clientS.watch}
          client_id={clientS.id}
          reload={loadClientSingle}
        />
      </div>

      <div
        style={{
          flex: 0.5,
          height: "55px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => setbrokerage(true)}
          style={{
            padding: 10,
            backgroundColor: Dark.primary,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 7,
            color: Dark.text,
          }}
        >
          Symbol Brokerage
        </div>

        <FreshLimit
          status={clientS.fresh_trade}
          client_id={clientS.id}
          reload={loadClientSingle}
        />

        <AutoSq
          status={clientS.auto_sq}
          client_id={clientS.id}
          reload={loadClientSingle}
        />

        <MidLimit
          status={clientS.mid_limit}
          client_id={clientS.id}
          reload={loadClientSingle}
        />

        <OnlySq
          status={clientS.only_sq}
          client_id={clientS.id}
          reload={loadClientSingle}
        />

        <Intraday
          status={clientS.intraday}
          client_id={clientS.id}
          reload={loadClientSingle}
        />

        {/* <MaxQty
          status={clientS.max_qty}
          client_id={clientS.id}
          reload={loadClientSingle}
        /> */}
      </div>

      <Dialog
        visible={show_edit_client_details}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setshow_edit_client_details(false)}
      >
        <TPassword
          Update={edit_client_details}
          tpassword={(a) => setTPassword(a)}
        />
      </Dialog>

      <Dialog
        visible={show_edit_master_brokerage}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setshow_edit_master_brokerage(false)}
      >
        <TPassword
          Update={edit_master_brokerage}
          tpassword={(a) => setTPassword(a)}
        />
      </Dialog>
      <Dialog
        header="SYMBOL WISE BROKERAGE"
        visible={brokerage}
        className="modal"
        style={{ width: "45vw", height: "60vh" }}
        onHide={() => setbrokerage(false)}
      >
        <SymbolBrokerage client_id={clientS.id} reload={loadClientSingle} />
      </Dialog>
    </div>
  );
}

import React from "react";
import { Dark } from "../../../../../Theme/Theme";
import moment from "moment";
export default function ClientCard(props) {
  const i = props.item;
  return (
    <div
      style={{
        height: 40,
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 0.2,
        borderBottomColor: Dark.primary,
        display: "flex",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {i.username}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {i.future_brokerage}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {i.mcx_brokerage}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {i.options_brokerage}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {i.future_broker}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {i.mcx_broker}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {i.options_broker}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {i.broker_sharing}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          fontSize: 12,
        }}
      >
        <div>{moment(i.date_created).format("DD-MM-YYYY")}</div>
        <div>{moment(i.date_created).format("HH:mm:ss")}</div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 5,
        }}
      >
        <div
        onClick={()=>props.show(i)}
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 14,
            backgroundColor: Dark.primary,
            borderRadius: 10,
            height: 32,
          }}
        >
          EDIT
        </div>
      </div>
    </div>
  );
}

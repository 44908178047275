import React, { useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import moment from "moment";
import { toast } from "react-toast";
import Backend from "../../../../../Backend/Backend";
import Loading from "react-loading";
import TPassword from "../../../../../Healer/TPassword/TPassword";
import { Dialog } from "primereact/dialog";
const backend = new Backend();
export default function LedgerCard(props) {
  const client = props.client;
  const i = props.item;

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [tpassword, setTPassword] = useState("");

  const remove_block = () => {
    setShow(false);
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: client.id,
      symbol_id: i.id,
      tpassword: tpassword,
    };
    backend.client_block_remove(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.error(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.reload();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        height: 50,
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 0.2,
        borderBottomColor: Dark.primary,
        display: "flex",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {i.symbol}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {moment(i.date_created).format("DD-MM-YYYY hh:mm:ss")}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => setShow(true)}
          style={{ padding: 7, backgroundColor: Dark.sell, borderRadius: 7 }}
        >
          {loading ? (
            <Loading type="spin" height={25} width={25} color={Dark.text} />
          ) : (
            "REMOVE"
          )}
        </div>
      </div>
      <Dialog
        visible={show}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "32vh" }}
        onHide={() => setShow(false)}
      >
        <TPassword Update={remove_block} tpassword={(a) => setTPassword(a)} />
      </Dialog>
    </div>
  );
}

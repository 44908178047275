import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import { InputText } from "primereact/inputtext";
import "./Edit.css";
import Loading from "react-loading";
import Active from "./Active";
import TPassword from "../../../../../Healer/TPassword/TPassword";
import { Dialog } from "primereact/dialog";
import { ToWords } from "to-words";
import { NumericFormat } from "react-number-format";

const toWords = new ToWords({
  localeCode: "hi-IN",
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: "₹",
      plural: "₹",
      symbol: "₹",
      fractionalUnit: {
        name: "Paisa",
        plural: "Paise",
        symbol: "",
      },
    },
  },
});

const backend = new Backend();

export default function Edit(props) {
  const broker = props.broker;

  const [loading, setLoading] = useState(false);

  const [show_edit_broker_details, setshow_edit_broker_details] =
    useState(false);

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [tpassword, setTPassword] = useState("");

  const edit_broker_details = () => {
    setLoading(true);
    setshow_edit_broker_details(false);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      broker_id: broker.id,
      name: name == "" ? broker.broker_name : name,
      password: password,
      tpassword: tpassword,
    };

    backend.edit_broker_details(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.reload();
        setName("");
        setPassword("");
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        height: "90%",
      }}
    >
      <div
        key={broker.id}
        style={{
          flex: 0.6,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            color: Dark.text,
            padding: 10,
            textTransform: "uppercase",
            backgroundColor: Dark.primary,
            display: "flex",
          }}
        >
          <div style={{ flex: 1 }}>Broker details</div>
        </div>
        <div
          style={{
            marginTop: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>
              {broker.broker_name}
            </div>
            <div className="loginInputEditAdmin">
              <InputText
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Name"
              />
            </div>
          </div>

          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ color: Dark.text, marginTop: 5 }}>PASSWORD</div>
            <div className="loginInputEditAdmin">
              <InputText
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="Password"
              />
            </div>
          </div>
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              onClick={() =>
                loading ? null : setshow_edit_broker_details(true)
              }
              style={{
                color: Dark.text,
                height: 40,
                width: 100,
                backgroundColor: Dark.primary,
                paddingLeft: 25,
                paddingRight: 25,
                borderRadius: 7,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading ? (
                <Loading type="spin" height={25} width={25} color={Dark.text} />
              ) : (
                "SAVE"
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "55px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Active status={broker.status} broker_id={broker.id} />
      </div>

      <Dialog
        visible={show_edit_broker_details}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setshow_edit_broker_details(false)}
      >
        <TPassword
          Update={edit_broker_details}
          tpassword={(a) => setTPassword(a)}
        />
      </Dialog>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import SocketContext from "../SocketContect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dark } from "../../Theme/Theme";
import useSound from "use-sound";
import Buy from "../../Sound/buy.mp3";
import Sell from "../../Sound/sell.mp3";

export default function Notification() {
  const socket = useContext(SocketContext);
  const [playSoundBuy] = useSound(Buy);
  const [playSoundSell] = useSound(Sell);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    socket.on("master", master);
    return () => {
      socket.off("master", master);
    };
  }, [reload]);

  const master = (a) => {
    if (
      localStorage.getItem("id") == null ||
      localStorage.getItem("id") == undefined ||
      localStorage.getItem("id") == ""
    ) {
    } else {
      if (a.master == localStorage.getItem("id")) {
        setReload(!reload);
        if (a.buy_sell == 0) {
          playSoundBuy();
          let text = `${a.username}(${a.name}) - ${a.type} `;
          toast.success(text);
        } else {
          playSoundSell();
          let text = `${a.username}(${a.name}) - ${a.type} `;
          toast.error(text);
        }
      }
    }
  };

  return <ToastContainer />;
}

import React, { useEffect, useState } from "react";
import { Dark, Light } from "../../../Theme/Theme";
import Logo from "../../../Image/Logo.png";
import LogoSub from "../../../Image/LogoSub.png";
import { BiLogOut, BiUserCircle } from "react-icons/bi";
import { Dialog } from "primereact/dialog";
import "./Topview.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Profile from "./Profile/Profile";
import Ledger from "./Ledger/Ledger";
import { toast } from "react-toast";
import Backend from "../../../Backend/Backend";
const backend = new Backend();

export default function TopView() {
  const path = window.location.pathname;
  const navigation = useNavigate();
  const theme = useSelector((state) => state.theme);

  const [select, setSelect] = useState(false);
  const [profile, setProfile] = useState(false);

  const [profiledata, setProfileData] = useState(false);

  const [ledger, setLedger] = useState(false);

  const [checked, setChecked] = useState(1);

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      username: localStorage.getItem("username"),
    };

    backend.profile(data).then((r) => {
      if (r.error == "False") {
        setProfileData(r.profile);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const Logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("username");
    localStorage.removeItem("server");
    localStorage.removeItem("AuthState");
    window.location = "/";
  };

  onkeydown = (event) => {
    if (event.shiftKey == true) {
      if (event.key == "e" || event.key == "E") {
        event.preventDefault();
        navigation("/");
      }

      if (event.key == "w" || event.key == "W") {
        event.preventDefault();
        navigation("/Watchlist");
      }

      if (event.key == "c" || event.key == "C") {
        event.preventDefault();
        navigation("/Client");
      }

      if (event.key == "s" || event.key == "S") {
        event.preventDefault();
        navigation("/Position");
      }

      if (event.key == "t" || event.key == "T") {
        event.preventDefault();
        navigation("/Trade");
      }

      if (event.key == "p" || event.key == "P") {
        event.preventDefault();
        navigation("/Pending");
      }

      if (event.key == "h" || event.key == "H") {
        event.preventDefault();
        navigation("/Holding");
      }

      if (event.key == "b" || event.key == "B") {
        event.preventDefault();
        navigation("/Broker");
      }

      if (event.key == "r" || event.key == "R") {
        event.preventDefault();
        navigation("/Report");
      }

      if (event.key == "t" || event.key == "T") {
        event.preventDefault();
        navigation("/Payin");
      }

      if (event.ctrlKey == true) {
        if (event.key == "t" || event.key == "T") {
          event.preventDefault();
          navigation("/PositionTrade");
        }

        if (event.key == "c" || event.key == "C") {
          event.preventDefault();
          navigation("/PositionClient");
        }

        if (event.key == "p" || event.key == "P") {
          event.preventDefault();
          navigation("/ParkingPosition");
        }

        if (event.key == "b" || event.key == "B") {
          event.preventDefault();
          navigation("/Brokerage");
        }

        if (event.key == "a" || event.key == "A") {
          event.preventDefault();
          navigation("/Payin");
        }

        if (event.key == "o" || event.key == "O") {
          event.preventDefault();
          navigation("/Payout");
        }

        if (event.key == "n" || event.key == "N") {
          event.preventDefault();
          navigation("/Account");
        }
      }
    }
  };

  return (
    <div key={select} className="topview">
      <div
        onClick={() => {
          setSelect(!select);
          navigation("/");
        }}
        className="topviewcontainer"
      >
        <img src={Logo} className="topviewlogo" />
        <img src={LogoSub} className="topviewsublogo" />
      </div>
      <div className="topviewmain">
        <div
          onClick={() => {
            setSelect(!select);
            navigation("/");
          }}
          className="link linkcontanier"
          style={{
            backgroundColor:
              path == "/" || path == "/Home" ? Dark.primary : Dark.background,
          }}
        >
          <div>HOM</div>
          <div className="childtext">E</div>
        </div>

        <div
          onClick={() => {
            setSelect(!select);
            navigation("/Watchlist");
          }}
          className="link linkcontanier"
          style={{
            backgroundColor:
              path == "/Watchlist" ? Dark.primary : Dark.background,
          }}
        >
          <div className="childtext">W</div>
          <div>ATCHLIST</div>
        </div>

        <div
          onClick={() => {
            setSelect(!select);
            navigation("/Client");
          }}
          className="link linkcontanier"
          style={{
            backgroundColor: path == "/Client" ? Dark.primary : Dark.background,
          }}
        >
          <div className="childtext">C</div>
          <div>LIENT</div>
        </div>

        <div class="dropdown">
          <button
            class="dropbtn"
            style={{
              backgroundColor:
                path == "/PositionTrade" ||
                path == "/Position" ||
                path == "/ParkingPosition" ||
                path == "/PositionClient"
                  ? Dark.primary
                  : Dark.background,
              display: "flex",
            }}
          >
            <div>PO</div>
            <div className="childtext">S</div>
            <div>ITION</div>
          </button>
          <div class="dropdown-content">
            <div
              onClick={() => {
                setSelect(!select);
                navigation("/PositionTrade");
              }}
              className="link"
              style={{
                display: "flex",
              }}
            >
              <div className="childtext">T</div>
              <div>RADE WISE</div>
            </div>
            <div
              onClick={() => {
                setSelect(!select);
                navigation("/Position");
              }}
              className="link"
              style={{
                display: "flex",
              }}
            >
              <div className="childtext">P</div>
              <div>OSITION WISE</div>
            </div>
            <div
              onClick={() => {
                setSelect(!select);
                navigation("/PositionClient");
              }}
              className="link"
              style={{
                display: "flex",
              }}
            >
              <div className="childtext">C</div>
              <div>LIENT WISE</div>
            </div>

            <div
              onClick={() => {
                setSelect(!select);
                navigation("/ParkingPosition");
              }}
              className="link"
              style={{
                display: "flex",
              }}
            >
              <div className="childtext">P</div>
              <div>ARKING POSITION</div>
            </div>
          </div>
        </div>

        <div
          onClick={() => {
            setSelect(!select);
            navigation("/Trade");
          }}
          className="link linkcontanier"
          style={{
            backgroundColor: path == "/Trade" ? Dark.primary : Dark.background,
          }}
        >
          <div className="childtext">T</div>
          <div>RADE</div>
        </div>

        <div
          onClick={() => {
            setSelect(!select);
            navigation("/Pending");
          }}
          className="link linkcontanier"
          style={{
            backgroundColor:
              path == "/Pending" ? Dark.primary : Dark.background,
          }}
        >
          <div className="childtext">P</div>
          <div>ENDING</div>
        </div>

        <div
          onClick={() => {
            setSelect(!select);
            navigation("/Holding");
          }}
          className="link linkcontanier"
          style={{
            backgroundColor:
              path == "/Holding" ? Dark.primary : Dark.background,
          }}
        >
          <div className="childtext">H</div>
          <div>OLDING</div>
        </div>

        <div
          onClick={() => {
            setSelect(!select);
            navigation("/Broker");
          }}
          className="link linkcontanier"
          style={{
            backgroundColor: path == "/Broker" ? Dark.primary : Dark.background,
          }}
        >
          <div className="childtext">B</div>
          <div>ROKER</div>
        </div>

        <div class="dropdown">
          <button
            class="dropbtn"
            style={{
              backgroundColor:
                path == "/Report" || path == "/Brokerage"
                  ? Dark.primary
                  : Dark.background,
              display: "flex",
            }}
          >
            <div className="childtext">R</div>
            <div>EPORT</div>
          </button>
          <div class="dropdown-content">
            <div
              onClick={() => {
                setSelect(!select);
                navigation("/Report");
              }}
              className="link"
              style={{
                display: "flex",
              }}
            >
              <div className="childtext">C</div>
              <div>LIENT</div>
            </div>
            <div
              onClick={() => {
                setSelect(!select);
                navigation("/Brokerage");
              }}
              className="link"
              style={{
                display: "flex",
              }}
            >
              <div className="childtext">B</div>
              <div>ROKERAGE</div>
            </div>
          </div>
        </div>

        {profiledata == null ? null : profiledata.intraday == 0 ? null : (
          <div class="dropdown">
            <button
              class="dropbtn"
              style={{
                backgroundColor:
                  path == "/Payin" || path == "/Payout"
                    ? Dark.primary
                    : Dark.background,
                display: "flex",
              }}
            >
              <div>WALLE</div>
              <div className="childtext">T</div>
            </button>
            <div class="dropdown-content">
              <div
                onClick={() => {
                  setSelect(!select);
                  navigation("/Payin");
                }}
                className="link"
                style={{
                  display: "flex",
                }}
              >
                <div className="childtext">P</div>
                <div>AYIN</div>
              </div>
              <div
                onClick={() => {
                  setSelect(!select);
                  navigation("/Payout");
                }}
                className="link"
                style={{
                  display: "flex",
                }}
              >
                <div className="childtext">P</div>
                <div>AYOUT</div>
              </div>
              <div
                onClick={() => {
                  setSelect(!select);
                  navigation("/Account");
                }}
                className="link"
                style={{
                  display: "flex",
                }}
              >
                <div className="childtext">A</div>
                <div>CCOUNT</div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        style={{
          width: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div class="dropdownprofile">
          <button class="dropbtnprofile">
            {" "}
            <BiUserCircle
              color={theme == 0 ? Dark.text : Light.text}
              fontSize={30}
            />{" "}
          </button>
          <div class="dropdown-contentprofile">
            <div
              onClick={() => {
                setSelect(!select);
                setProfile(true);
              }}
              className="link"
            >
              PROFILE
            </div>
            <div
              onClick={() => {
                setSelect(!select);
                setLedger(true);
              }}
              className="link"
            >
              LEDGER
            </div>
          </div>
        </div>

        <div onClick={Logout} style={{ marginLeft: 20 }}>
          <BiLogOut color={Dark.text} fontSize={30} />
        </div>
      </div>

      <Dialog
        showHeader={false}
        visible={profile}
        style={{
          width: "40vw",
          height: "55vh",
        }}
        onHide={() => setProfile(false)}
      >
        <Profile />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={ledger}
        className="modalpo"
        style={{ width: "50vw", height: "68vh" }}
        onHide={() => setLedger(false)}
      >
        <Ledger />
      </Dialog>
    </div>
  );
}

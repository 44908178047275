import React, { useEffect, useState } from "react";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import { Dark } from "../../../../../Theme/Theme";
import Loading from "react-loading";

const backend = new Backend();

export default function ForceSqClient() {
  const [loading, setLoading] = useState(false);

  const [client, setClient] = useState([]);

  useEffect(() => {
    LoadClientForce();
  }, []);

  const LoadClientForce = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.client_force(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setClient(r.forceclient);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div>
      {loading ? (
        <div
          style={{
            height: "53vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading type="spin" height={30} width={30} />
        </div>
      ) : client.length > 0 ? (
        <div
          style={{
            height: "53vh",
            display: "flex",
            overflowY: "scroll",
            flexDirection: "column",
          }}
        >
          {client.map((i) => {
            return (
              <div
                style={{
                  color: Dark.text,
                  marginTop: 10,
                  padding:10,
                  backgroundColor: Dark.background,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <div style={{flex:1}} >
                  {i.username}({i.name})
                </div>
                <div
                  style={{
                    color: i.deposit_current > 0 ? Dark.buy : Dark.sell,
                    flex:1
                  }}
                >
                  {" "}
                  BALANCE : {i.deposit_current}
                </div>
                <div
                  style={{
                    color: i.deposit_total > 0 ? Dark.buy : Dark.sell,
                    flex:1
                  }}
                >
                  {" "}
                  NET BALANCE : {i.deposit_total}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          style={{
            height: "53vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
            {" "}
            Nothing here
          </div>
          <div style={{ color: Dark.text }}>No Force Sq Client Found.</div>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../../Theme/Theme";
import Backend from "../../../../../../Backend/Backend";
import { toast } from "react-toast";
import { Dialog } from "primereact/dialog";
import TPassword from "../../../../../../Healer/TPassword/TPassword";
import { InputText } from "primereact/inputtext";
import "./Edit.css";
const backend = new Backend();
export default function Edit(props) {
  const clientdata = props.clientdata;

  const [loading, setLoading] = useState(false);

  const [masterS, setMasterS] = useState("");
  const [superS, setSuperS] = useState("");
  const [adminS, setAdminS] = useState("");

  const [fut_broker, setfut_broker] = useState(0);
  const [mcx_broker, setmcx_broker] = useState(0);
  const [fo_broker, setfo_broker] = useState(0);
  const [plsharing, setplsharing] = useState(0);

  const [tpassword, setTPassword] = useState("");

  const [show_remove, setshow_remove] = useState(false);
  const [show_update, setshow_update] = useState(false);

  useEffect(() => {
    loadMaster();
    loadSuper();
    loadAdmin();
  }, []);

  const RemoveBroker = () => {
    setshow_remove(false);
    props.close();
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: clientdata.id,
      tpassword: tpassword,
    };
    backend.broker_remove(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };
  const UpdateBrokerBrokerage = () => {
    setshow_update(false);
    props.close();
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: clientdata.id,
      broker_id: clientdata.broker_id,
      future_broker: fut_broker == "" ? clientdata.future_broker : fut_broker,
      mcx_broker: mcx_broker == "" ? clientdata.mcx_broker : mcx_broker,
      options_broker: fo_broker == "" ? clientdata.options_broker : fo_broker,
      plsharing: plsharing == "" ? clientdata.broker_sharing : plsharing,
      tpassword: tpassword,
    };
    backend.broker_brokerage(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        setfut_broker("");
        setmcx_broker("");
        setfo_broker("");
        setplsharing("");
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadMaster = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.load_master(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setMasterS(r.master);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadSuper = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      super_id: clientdata.super_id,
    };

    backend.load_super(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setSuperS(r.super);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadAdmin = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      admin_id: clientdata.admin_id,
    };

    backend.load_admin(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setAdminS(r.admin);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  let future_max = parseFloat(
    parseFloat(clientdata.future_brokerage) -
      parseFloat(masterS.future_min_volume)
  ).toFixed(3);

  let mcx_max =
    clientdata.lot_volume == 0
      ? parseFloat(
          parseFloat(clientdata.mcx_brokerage) - parseFloat(masterS.mcx_min_lot)
        ).toFixed(0)
      : parseFloat(
          parseFloat(clientdata.mcx_brokerage) -
            parseFloat(masterS.mcx_min_volume)
        ).toFixed(3);

  let options_max = parseFloat(
    parseFloat(clientdata.options_brokerage) -
      parseFloat(masterS.options_min_lot)
  ).toFixed(0);

  let sharing =
    100 -
    parseFloat(
      parseFloat(adminS.sharing) +
        parseFloat(superS.sharing) +
        parseFloat(masterS.sharing)
    );

  return (
    <div
      style={{
        flex: 1,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {future_max == 0 ? null : (
        <div
          style={{
            height: 60,
            backgroundColor: Dark.background,
            marginTop: 20,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            FUTURE : {future_max}
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="loginInputBroker">
              <InputText
                value={fut_broker}
                onChange={(e) => {
                  if (parseFloat(future_max) >= parseFloat(e.target.value)) {
                    setfut_broker(e.target.value);
                  } else {
                    setfut_broker("");
                  }
                }}
                autoFocus={true}
                placeholder="Enter Brokerage"
              />
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            MAX : {future_max}
          </div>
        </div>
      )}

      {mcx_max == 0 ? null : (
        <div
          style={{
            height: 60,
            backgroundColor: Dark.background,
            marginTop: 20,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            MCX : {mcx_max}
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="loginInputBroker">
              <InputText
                value={mcx_broker}
                onChange={(e) => {
                  if (parseFloat(mcx_max) >= parseFloat(e.target.value)) {
                    setmcx_broker(e.target.value);
                  } else {
                    setmcx_broker("");
                  }
                }}
                autoFocus={true}
                placeholder="Enter Brokerage"
              />
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            MAX : {mcx_max}
          </div>
        </div>
      )}

      {options_max == 0 ? null : (
        <div
          style={{
            height: 60,
            backgroundColor: Dark.background,
            marginTop: 20,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            OPTIONS : {options_max}
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="loginInputBroker">
              <InputText
                value={fo_broker}
                onChange={(e) => {
                  if (parseFloat(options_max) >= parseFloat(e.target.value)) {
                    setfo_broker(e.target.value);
                  } else {
                    setfo_broker("");
                  }
                }}
                autoFocus={true}
                placeholder="Enter Brokerage"
              />
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            MAX : {options_max}
          </div>
        </div>
      )}

      <div
        style={{
          height: 60,
          backgroundColor: Dark.background,
          marginTop: 20,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          SHARING : {sharing}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loginInputBroker">
            <InputText
              value={plsharing}
              onChange={(e) => {
                if (parseFloat(sharing) >= parseFloat(e.target.value)) {
                  setplsharing(e.target.value);
                } else {
                  setplsharing("");
                }
              }}
              autoFocus={true}
              placeholder="Enter Brokerage"
            />
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          MAX : {sharing}
        </div>
      </div>

      <div style={{display:"flex",justifyContent:"space-around",alignItems:"center"}} > 
        <div
          onClick={() => setshow_update(true)}
          style={{
            height: 40,
            backgroundColor: Dark.primary,
            marginTop: 20,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
            width: 200,
            alignSelf: "center",
          }}
        >
          UPDATE
        </div>

        <div
          onClick={() => setshow_remove(true)}
          style={{
            height: 40,
            backgroundColor: Dark.sell,
            marginTop: 20,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
            width: 200,
            alignSelf: "center",
          }}
        >
          REMOVE
        </div>
      </div>
{/* 
      <Dialog
        visible={show_remove}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setshow_remove(false)}
      >
        <TPassword Update={RemoveBroker} tpassword={(a) => setTPassword(a)} />
      </Dialog> */}
      <Dialog
        visible={show_update}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setshow_update(false)}
      >
        <TPassword
          Update={UpdateBrokerBrokerage}
          tpassword={(a) => setTPassword(a)}
        />
      </Dialog>
    </div>
  );
}

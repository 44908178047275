import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dark } from "../../../../Theme/Theme";
import { RiFileList3Line } from "react-icons/ri";
import { IoMdAddCircle } from "react-icons/io";
import { BiFilterAlt } from "react-icons/bi";
import { BsFilePdf } from "react-icons/bs";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import BrokerCard from "./BrokerCard";
import Loading from "react-loading";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Pdf from "./Pdf/Pdf";

const backend = new Backend();

export default function Broker(props) {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState("");
  const [brokerlist, setBrokerList] = useState([]);
  const [brokersearch, setBrokerSearch] = useState([]);

  const [keys, setkey] = useState(false);

  useEffect(() => {
    loadBroker();
  }, []);

  const loadBroker = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.load_broker(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setBrokerList(r.broker);
        setBrokerSearch(r.broker);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const searchSymbol = (a) => {
    if (a.length > 1) {
      var data = brokersearch.filter((obj) =>
        Object.keys(obj).some((key) =>
          obj[key].toString().toLowerCase().includes(a.toString().toLowerCase())
        )
      );
      setBrokerList(data);
    } else {
      setBrokerList(brokersearch);
    }
  };


  return (
    <div>
      <div
        style={{
          height: 50,
          backgroundColor: Dark.secondary,
          marginRight: 10,
          marginLeft: 10,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <div
          onClick={props.ShowSummary}
          style={{
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 50,
          }}
        >
          <RiFileList3Line size={27} color={Dark.text} />
        </div>
        <div style={{ flex: 1, padding: 10 }}>
          <InputText
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              searchSymbol(e.target.value);
            }}
            style={{
              height: 40,
              backgroundColor: Dark.background,
              borderStyle: "none",
              width: "100%",
              color: Dark.text,
            }}
            placeholder="Search Broker,Username"
          />
        </div>

        <div
          onClick={props.Add}
          style={{
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 50,
          }}
        >
          <IoMdAddCircle size={35} color={Dark.buy} />
        </div>

        {brokerlist.length > 0 ? (
          <div
            style={{
              height: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 50,
            }}
          >
            <PDFDownloadLink
              style={{
                textDecoration: "none",
              }}
              document={<Pdf data={brokerlist} />}
              fileName={"Broker-List"}
            >
              {({ blob, url, loading, error }) => (
                <div style={{ color: Dark.text }}>
                  <BsFilePdf size={30} color={Dark.text} />
                </div>
              )}
            </PDFDownloadLink>
          </div>
        ) : null}
      </div>

      <div
        key={keys}
        className="scroll"
        style={{
          margin: 10,
          height: "69vh",
          overflowY: "scroll",
        }}
      >
        {loading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading type="spin" color={Dark.text} height={35} width={35} />
          </div>
        ) : brokerlist.length > 0 ? (
          <div>
            {brokerlist.map((i) => {
              return (
                <BrokerCard
                  item={i}
                  broker={props.broker}
                  setBroker={(a) => props.setBroker(a)}
                />
              );
            })}
          </div>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: Dark.text,
            }}
          >
            NO BROKER FOUND.
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "react-loading";
import BrokerCard from "./BrokerCard";
import "./Broker.css";
import { Dialog } from "primereact/dialog";
import TPassword from "../../../../../Healer/TPassword/TPassword";
const backend = new Backend();

export default function Broker(props) {
  const client = props.client;
  const [loading, setLoading] = useState("");
  const [clientS, setClientS] = useState("");
  const [broker, setBroker] = useState(0);
  const [brokerlist, setBrokerList] = useState([]);

  const [broker_id, setBroker_id] = useState(0);

  const [tpassword, setTPassword] = useState(false);
  const [show_update, setShowUpdate] = useState(false);

  useEffect(() => {
    load_broker();
    loadClientSingle();
  }, []);

  const load_broker = () => {
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: client.id,
    };
    backend.client_broker(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setBroker(r.broker);
        setBrokerList(r.broker_list);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };
  const loadClientSingle = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: client.id,
    };

    backend.single_client(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setClientS(r.client);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const UpdateBroker = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: client.id,
      broker_id: broker_id,
      tpassword: tpassword,
    };
    backend.broker_update(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        setShowUpdate(false);
        load_broker();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div key={clientS} style={{ display: "flex", height: "100%" }}>
      <div
        style={{
          flex: 2,
          height: "95%",
        }}
      >
        <div
          style={{
            backgroundColor: Dark.primary,
            padding: 10,
            color: Dark.text,
            textTransform: "uppercase",
            display: "flex",
          }}
        >
          <div style={{ flex: 1 }}>YOUR BROKER</div>
        </div>

        <div
          style={{
            color: Dark.text,
            textTransform: "uppercase",
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          {broker == 0 ? (
            <div
              style={{
                height: "55vh",
                backgroundColor: Dark.secondary,
                width: "40%",
                borderRadius: 10,
              }}
            >
              <div
                style={{
                  padding: 15,
                  textAlign: "center",
                  backgroundColor: Dark.primary,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  color: Dark.text,
                }}
              >
                SELECT BROKER
              </div>

              <div
                className="scroll"
                style={{
                  height: "42vh",
                  backgroundColor: Dark.secondary,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  overflowY: "scroll",
                }}
              >
                {loading ? (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loading
                      type="spin"
                      height={40}
                      width={40}
                      color={Dark.text}
                    />
                  </div>
                ) : brokerlist.length > 0 ? (
                  brokerlist.map((i, t) => {
                    return (
                      <BrokerCard
                        item={i}
                        update={(a) => setBroker_id(a)}
                        broker_id={broker_id}
                      />
                    );
                  })
                ) : (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: Dark.text,
                    }}
                  >
                    NO BROKER FOUND
                  </div>
                )}
              </div>

              <div
                onClick={() =>
                  loading
                    ? null
                    : brokerlist.length > 0
                    ? setShowUpdate(true)
                    : null
                }
                style={{
                  padding: 15,
                  textAlign: "center",
                  backgroundColor: Dark.buy,
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                  opacity: brokerlist.length > 0 ? 1 : 0.2,
                  color: Dark.text,
                }}
              >
                UPDATE BROKER
              </div>
            </div>
          ) : (
            <div style={{ height: "90%", width: "100%" }}>
              <div
                style={{
                  height: 55,
                  backgroundColor: Dark.primary,
                  fontSize: 22,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                BROKER : {broker.broker_name}
              </div>

              <div
                style={{
                  height: 55,
                  backgroundColor: Dark.secondary,
                  fontSize: 14,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  CLIENT BROKERAGE
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  FUTURE :{" "}
                  <div style={{ paddingLeft: 3, fontSize: 18 }}>
                    {clientS.future_brokerage}{" "}
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  MCX :{" "}
                  <div style={{ paddingLeft: 3, fontSize: 18 }}>
                    {clientS.mcx_brokerage}
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  OPTIONS :{" "}
                  <div style={{ paddingLeft: 3, fontSize: 18 }}>
                    {clientS.options_brokerage}
                  </div>
                </div>
              </div>

              <div
                style={{
                  height: 55,
                  backgroundColor: Dark.secondary,
                  fontSize: 14,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  BROKER BROKERAGE
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  FUTURE :{" "}
                  <div style={{ paddingLeft: 3, fontSize: 18 }}>
                    {clientS.future_broker == null ? 0 : clientS.future_broker}{" "}
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  MCX :{" "}
                  <div style={{ paddingLeft: 3, fontSize: 18 }}>
                    {clientS.mcx_broker == null ? 0 : clientS.mcx_broker}{" "}
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  OPTIONS :{" "}
                  <div style={{ paddingLeft: 3, fontSize: 18 }}>
                    {clientS.options_broker == null
                      ? 0
                      : clientS.options_broker}{" "}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Dialog
        visible={show_update}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setShowUpdate(false)}
      >
        <TPassword Update={UpdateBroker} tpassword={(a) => setTPassword(a)} />
      </Dialog>
    </div>
  );
}

import React, { Fragment, useContext, useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import SocketContext from "../../../../Healer/SocketContect";
import { toast } from "react-toast";
import { AiFillCloseCircle } from "react-icons/ai";
import Backend from "../../../../Backend/Backend";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import useSound from "use-sound";
import Errors from "../../../../Sound/error.mp3";
import Buy from "../../../../Sound/buy.mp3";
import Sell from "../../../../Sound/sell.mp3";
import ClientCard from "./ClientCard";

import "./BuySell.css";
const backend = new Backend();
export default function BuySellCard(props) {
  const symbol = props.symbol;
  const socket = useContext(SocketContext);
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [high2, sethigh2] = useState(0);
  const [low2, setlow2] = useState(0);
  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ltp, setltp] = useState(0);
  const [open, setOpen] = useState(0);
  const [close, setClose] = useState(0);

  const [bidcolor, setbidcolor] = useState("");
  const [askcolor, setaskcolor] = useState("");
  const [ltpcolor, setltpcolor] = useState("");

  const [loading, setLoading] = useState(false);

  const [qty_order, setQty_order] = useState("");

  const [clientlist, setClientList] = useState([]);
  const [clientsearch, setClientSearch] = useState([]);

  const [search, setSearch] = useState("");

  const [client, setClient] = useState("");
  const [password, setPassword] = useState("");

  const [playSoundError] = useSound(Errors);
  const [playSoundBuy] = useSound(Buy);
  const [playSoundSell] = useSound(Sell);

  useEffect(() => {
    loadClient();
    socket.emit("giverate", symbol.symbol_id);
    socket.on("trade" + symbol.symbol_id, trade);
    socket.on("bidask" + symbol.symbol_id, bidask);
    return () => {
      socket.off("trade" + symbol.symbol_id, trade);
      socket.off("bidask" + symbol.symbol_id, bidask);
    };
  }, []);

  const trade = (msg) => {
    if (msg == null) {
      // alert.error("Script is expire no trading available");
    } else {
      if (bid !== msg.Bid) {
        if (msg.Bid > bid) {
          setbidcolor(Dark.buy);
        } else {
          setbidcolor(Dark.sell);
        }
        setbid(msg.Bid);
      }
      if (ask !== msg.Ask) {
        if (msg.Ask > ask) {
          setaskcolor(Dark.buy);
        } else {
          setaskcolor(Dark.sell);
        }
        setask(msg.Ask);
      }
      if (high2 !== msg.High) {
        sethigh2(msg.High);
      }
      if (low2 !== msg.Low) {
        setlow2(msg.Low);
      }
      if (msg.LTP - msg.Previous_Close !== chng) {
        setchng(msg.LTP - msg.Previous_Close);
        setpercent(((msg.LTP - msg.Previous_Close) / msg.Previous_Close) * 100);
      }
      if (ltp !== msg.LTP) {
        if (msg.LTP > ltp) {
          setltpcolor(Dark.buy);
        } else {
          setltpcolor(Dark.sell);
        }
        setltp(msg.LTP);
      }
      if (open !== msg.Open) {
        setOpen(msg.Open);
      }
      if (close !== msg.Previous_Close) {
        setClose(msg.Previous_Close);
      }
    }
  };
  const bidask = (msg) => {
    if (bid !== msg.Bid) {
      setbid(msg.Bid);
      if (msg.Bid > bid) {
        setbidcolor(Dark.buy);
      } else {
        setbidcolor(Dark.sell);
      }
    }
    if (ask !== msg.Ask) {
      if (msg.Ask > ask) {
        setaskcolor(Dark.buy);
      } else {
        setaskcolor(Dark.sell);
      }
      setask(msg.Ask);
    }
  };

  const loadClient = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.load_client(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setClientList(r.client);
        setClientSearch(r.client);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
      }
    });
  };
  const Buy_Sell = (a) => {
    if (qty_order < 0) {
      toast.error("Invalid Order Qty !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
      error();
    } else {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        server: localStorage.getItem("server"),
        username: localStorage.getItem("username"),
        qty_order: qty_order,
        symbol_id: symbol.symbol_id,
        symbol: symbol.symbol_display,
        symbol_type: props.symbol_type,
        buy_sell: a,
        client_id: client.id,
        tpassword: password,
      };
      backend.buy_sell(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
          if (a == 0) {
            buy();
          } else {
            sell();
          }
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
          error();
        }
      });
    }
  };

  const buy = () => {
    if (localStorage.getItem("sound") == 1) {
      playSoundBuy();
    }
  };
  const sell = () => {
    if (localStorage.getItem("sound") == 1) {
      playSoundSell();
    }
  };
  const error = () => {
    if (localStorage.getItem("sound") == 1) {
      playSoundError();
    }
  };

  const AddClient = (a) => {
    setClient(a);
  };

  const RemoveClient = () => {
    setClient("");
  };

  const searchSymbol = (a) => {
    if (a.length > 1) {
      var data = clientsearch.filter((x) =>
        x.username.toLowerCase().includes(a.toLowerCase())
      );
      setClientList(data);
    } else {
      setClientList(clientsearch);
    }
  };

  return (
    <div style={{ marginRight: 10, marginLeft: 10 }}>
      <div
        style={{
          padding: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        ESC TO CLOSE
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <div
            style={{
              height: 50,
              backgroundColor: Dark.background,
              marginBottom: 10,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={"loginInputSearch"}>
              <InputText
                value={search}
                onChange={(e) => {
                  searchSymbol(e.target.value);
                  setSearch(e.target.value);
                }}
                style={{
                  fontSize: 12,
                }}
                placeholder={"SEARCH CLIENT"}
              />
            </div>
            <div
              onClick={() => {
                searchSymbol("");
                setSearch("");
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 50,
                height: 50,
              }}
            >
              <AiFillCloseCircle size={30} color={Dark.sell} />
            </div>
          </div>

          <div style={{ height: "40vh", overflowY: "scroll" }}>
            {clientlist.map((i, t) => {
              return (
                <ClientCard
                  key={i.id}
                  item={i}
                  client={client}
                  AddClient={AddClient}
                  RemoveClient={RemoveClient}
                />
              );
            })}
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 120,
              backgroundColor: Dark.secondary,
              borderRadius: 10,
              flexDirection: "column",
            }}
          >
            <div
              style={{
                height: 80,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  paddingLeft: 20,
                  height: "100%",
                  paddingTop: 5,
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    paddingBottom: 7,
                  }}
                >
                  <div
                    style={{ fontSize: 12, paddingBottom: 5, color: Dark.text }}
                  >
                    BID
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      color: bidcolor == "" ? Dark.text : bidcolor,
                    }}
                  >
                    {bid}
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{ fontSize: 12, paddingBottom: 5, color: Dark.text }}
                  >
                    ASK
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      color: askcolor == "" ? Dark.text : askcolor,
                    }}
                  >
                    {ask}
                  </div>
                </div>
              </div>
              <div
                style={{
                  flex: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{ paddingBottom: 5, fontSize: 16, color: Dark.text }}
                >
                  {symbol.symbol_display}
                </div>
                <div
                  style={{ paddingBottom: 5, fontSize: 12, color: Dark.text }}
                >
                  {moment(symbol.expiry_date).format("DD-MMM")}
                </div>
                <div
                  style={{
                    paddingBottom: 5,
                    fontSize: 16,
                    color: ltpcolor == "" ? Dark.text : ltpcolor,
                  }}
                >
                  LTP : {ltp}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  paddingRight: 20,
                  height: "100%",
                  paddingTop: 5,
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    paddingBottom: 7,
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{ fontSize: 12, paddingBottom: 5, color: Dark.buy }}
                  >
                    HIGH
                  </div>
                  <div style={{ fontSize: 14, color: Dark.buy }}>{high2}</div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{ fontSize: 12, paddingBottom: 5, color: Dark.sell }}
                  >
                    LOW
                  </div>
                  <div style={{ fontSize: 14, color: Dark.sell }}>{low2}</div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 45,
              margin: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              <div
                style={{
                  color: Dark.text,
                  textAlign: "center",
                  fontSize: 12,
                  flex: 1,
                }}
              >
                {props.symbol_type == "fut" ? "QTY" : "LOT"}
              </div>
              <div
                style={{
                  flex: 1,
                }}
              >
                <div className={"loginInputTrade"}>
                  <InputText
                    value={qty_order}
                    onChange={(e) => {
                      setQty_order(e.target.value);
                    }}
                    style={{
                      fontSize: 12,
                    }}
                    placeholder={
                      props.symbol_type == "fut"
                        ? "ENTER QTY"
                        : "ENTER LOT SIZE"
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 45,
              margin: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              <div
                style={{
                  color: Dark.text,
                  textAlign: "center",
                  fontSize: 12,
                  flex: 1,
                }}
              >
                {"TPASSWORD"}
              </div>
              <div
                style={{
                  flex: 1,
                }}
              >
                <div className={"loginInputTrade"}>
                  <InputText
                    value={password}
                    type="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    style={{
                      fontSize: 12,
                    }}
                    placeholder={"ENTER TPASSWORD"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              bottom: 0,
              margin: 10,
              height: 45,
              backgroundColor: Dark.secondary,
              width: "50%",
              alignSelf: "center",
              right: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <>
              <div
                onClick={() => (loading ? null : Buy_Sell(0))}
                style={{
                  height: 40,
                  backgroundColor: Dark.buy,
                  flex: 1,
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: Dark.text,
                  fontSize: 18,
                }}
              >
                BUY
              </div>
              <div
                onClick={() => (loading ? null : Buy_Sell(1))}
                style={{
                  height: 40,
                  backgroundColor: Dark.sell,
                  flex: 1,
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: Dark.text,
                  fontSize: 18,
                }}
              >
                SELL
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import Menu from "./Menu";
import AccountCard from "./AccountCard";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import TopCard from "./TopCard";
import Loading from "react-loading";
const backend = new Backend();

export default function Account() {
  const [loading, setLoading] = useState(false);

  const [account, setAccount] = useState([]);

  useEffect(() => {
    loadAccount();
  }, []);

  const loadAccount = () => {
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.account(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setAccount(r.account);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        height: "82vh",
      }}
    >
      <div
        style={{
          flex: 5,
          backgroundColor: Dark.background,
          height: "100%",
        }}
      >
        <TopCard />
        <Menu />
        <div className="scroll" style={{ height: "71vh", overflowY: "scroll" }}>
          {loading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading type="spin" height={35} width={35} color={Dark.text} />
            </div>
          ) : account.length > 0 ? (
            account.map((i, t) => {
              return <AccountCard item={i} load={loadAccount} />;
            })
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
                {" "}
                Nothing here
              </div>
              <div style={{ color: Dark.text }}>No PayIn Found.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import Menu from "./Menu";
import ClientCard from "./ClientCard/ClientCard";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import TopCard from "./TopCard/TopCard";
import { Dialog } from "primereact/dialog";
import Trade from "./Trade/Trade";
import Loading from "react-loading";
const backend = new Backend();

export default function Brokerage() {
  const [loading, setLoading] = useState(false);

  const [pl, setPl] = useState(0);
  const [brokerage, setBrokerage] = useState(0);
  const [brokerage_broker, setBrokerage_broker] = useState(0);

  const [futurebrok, setFutureBrok] = useState(0);
  const [mcxbrok, setMcxBrok] = useState(0);
  const [optionsbrok, setOptionsBrok] = useState(0);

  const [futurebrokbroker, setFutureBrokBroker] = useState(0);
  const [mcxbrokbroker, setMcxBrokBroker] = useState(0);
  const [optionsbrokbroker, setOptionsBrokBroker] = useState(0);
  const [client, setClient] = useState([]);

  const [show, setShow] = useState(false);

  const [name, setName] = useState("");
  const [trade, setTrade] = useState([]);

  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());

  const [broker, setBroker] = useState("");

  useEffect(() => {
    loadBrokerage();
  }, []);

  const loadBrokerage = (a, b) => {
    setStart(a);
    setEnd(b);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      start: a == undefined ? start : a,
      end: b == undefined ? end : b,
      broker: broker.id,
    };

    backend.brokerage_report(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setClient(r.client);

        setPl(r.profit_loss);
        setBrokerage(r.brokerage);
        setBrokerage_broker(r.brokerage_broker);

        setFutureBrok(r.brokerage_future);
        setMcxBrok(r.brokerage_mcx);
        setOptionsBrok(r.brokerage_options);

        setFutureBrokBroker(r.brokerage_broker_future);
        setMcxBrokBroker(r.brokerage_broker_mcx);
        setOptionsBrokBroker(r.brokerage_broker_options);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadBrokerBrokerage = (a) => {
    setBroker(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      start: start,
      end: end,
      broker: a.broker,
    };

    backend.brokerage_report(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setClient(r.client);

        setPl(r.profit_loss);
        setBrokerage(r.brokerage);
        setBrokerage_broker(r.brokerage_broker);

        setFutureBrok(r.brokerage_future);
        setMcxBrok(r.brokerage_mcx);
        setOptionsBrok(r.brokerage_options);

        setFutureBrokBroker(r.brokerage_broker_future);
        setMcxBrokBroker(r.brokerage_broker_mcx);
        setOptionsBrokBroker(r.brokerage_broker_options);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };



  return (
    <div style={{ height: "82vh" }}>
      <div
        style={{
          display: "flex",
          height: "82vh",
        }}
      >
        <div
          style={{ flex: 5, backgroundColor: Dark.background, height: "100%" }}
        >
          <TopCard
            pl={pl}
            brokerage={brokerage}
            brokerage_broker={brokerage_broker}
            future={futurebrok}
            mcx={mcxbrok}
            optione={optionsbrok}
            futurebroker={futurebrokbroker}
            mcxbroker={mcxbrokbroker}
            optionebroker={optionsbrokbroker}
            client={client}
            start={start}
            brokerdata={broker}
            end={end}
            broker={loadBrokerBrokerage}
            filter={loadBrokerage}
          />
          <Menu />
          <div
            className="scroll"
            style={{ height: "77%", overflowY: "scroll" }}
          >
            {loading ? (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading type="spin" color={Dark.text} height={35} width={35} />
              </div>
            ) : client.length > 0 ? (
              client.map((i, t) => {
                return (
                  <ClientCard
                    item={i}
                    index={t}
                    show={() => setShow(true)}
                    name={(a) => setName("ALL TRADE OF " + a)}
                    trade={(a) => setTrade(a)}
                  />
                );
              })
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  textTransform:"uppercase"
                }}
              >
                <div
                  style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}
                >
                  {" "}
                  Nothing here
                </div>
                <div style={{ color: Dark.text }}>
                  No Brokerage Report Found.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Dialog
        header={name.toUpperCase()}
        visible={show}
        className="modalpo"
        style={{ width: "50vw", height: "70vh" }}
        onHide={() => setShow(false)}
      >
        <Trade trade={trade} />
      </Dialog>
    </div>
  );
}

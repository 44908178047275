import React, { useState } from "react";
import BrokerList from "./BrokerList/BrokerList";
import Brokers from "./Broker/Broker";
import AddBroker from "./AddBroker/AddBroker";
import { Dark } from "../../../Theme/Theme";

import Summary from "./Summary/Summary";
export default function Broker() {
  const height = window.innerHeight;
  const [type, setType] = useState(0);
  const [broker, setBroker] = useState("");
  const [update, setupdate] = useState(0);

  const AddNewBroker = () => {
    setType(1);
  };
  const ShowSummary = () => {
    setType(0);
  };

  return (
    <div
      style={{
        height: "100%",
        height: height / 1.24,
        display: "flex",
      }}
    >
      <div style={{ flex: 3, margin: 10 }}>
        {type == 0 ? <Summary /> : null}
        {type == 2 ? (
          <BrokerList
            broker={broker}
            reload={() => {
              setupdate(1);
            }}
          />
        ) : null}
        {type == 1 ? (
          <AddBroker
            reload={() => {
              setupdate(1);
              ShowSummary();
            }}
          />
        ) : null}
      </div>
      <div style={{ flex: 1, backgroundColor: Dark.background }}>
        <Brokers
          Add={AddNewBroker}
          ShowSummary={ShowSummary}
          setBroker={(a) => {
            setBroker(a);
            setType(2);
          }}
          key={update}
          broker={broker}
        />
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import { Dark } from "../../../../../Theme/Theme";
import Menu from "./Menu";
import { Dialog } from "primereact/dialog";
import ClientCard from "./ClientCard";
import ClientEdit from "./Edit/Edit";
import Loading from "react-loading";
const backend = new Backend();

export default function LoginLog(props) {
  let broker = props.broker;

  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState([]);

  const [show, setShow] = useState(false);
  const [clientdata, setClientData] = useState("");

  useEffect(() => {
    load_client();
  }, []);

  const load_client = () => {
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      broker_id: broker.id,
    };
    backend.broker_client(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setClient(r.client);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Menu client={client} username={broker.broker_name} />
      <div className="scroll" style={{ height: "62vh", overflowY: "scroll" }}>
        {loading ? (
          <div
            style={{
              height: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading type="spin" color={Dark.text} height={35} width={35} />
          </div>
        ) : client.length > 0 ? (
          client.map((i, t) => {
            return (
              <ClientCard
                item={i}
                index={t}
                show={(a) => {
                  setClientData(a);
                  setShow(true);
                }}
              />
            );
          })
        ) : (
          <div
            style={{
              height: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
              {" "}
              Nothing here
            </div>
            <div style={{ color: Dark.text }}>No Broker Client Found.</div>
          </div>
        )}
      </div>

      <Dialog
        header={
          clientdata == null ? null : clientdata.username + " CLIENT BROKERAGE"
        }
        visible={show}
        style={{ width: "50vw", height: "70vh" }}
        onHide={() => {
          setShow(false);
          setTimeout(() => {
            load_client();
          }, 300);
        }}
      >
        <ClientEdit
          clientdata={clientdata}
          close={() => {
            setShow(false);
            setTimeout(() => {
              load_client();
            }, 300);
          }}
        />
      </Dialog>
    </div>
  );
}

import React from "react";
import { Dark } from "../../../../../Theme/Theme";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Pdf from './Pdf/Pdf'
export default function Menu(props) {
  return (
    <div
      style={{
        height: 40,
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 2,
        borderBottomColor: Dark.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Location
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Device
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        IP
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        DATE
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 0.6,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 14,
          backgroundColor: Dark.primary,
          borderRadius: 10,
          height: 32,
        }}
      >
        <PDFDownloadLink
          style={{
            textDecoration: "none",
          }}
          document={<Pdf data={props.logs} />}
          fileName={`${props.username}-LoginLog`}
        >
          {({ blob, url, loading, error }) => (
            <div style={{ color: Dark.text }}>DOWNLOAD PDF</div>
          )}
        </PDFDownloadLink>
      </div>
    </div>
  );
}

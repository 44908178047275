import React, { useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import TopCard from "./TopCard/TopCard";
import Menu from "./Menu";
import PayOutCard from "./PayOutCard/PayOutCard";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "react-loading";

const backend = new Backend();

export default function PayOut() {
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(false);

  const [payout, setPayOut] = useState([]);
  const [payoutsearch, setPayOutSearch] = useState([]);

  const [total, setTotal] = useState(0);

  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());

  useEffect(() => {
    loadPayOut();
  }, []);

  const loadPayOut = (a, b) => {
    setStart(a);
    setEnd(b);
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      start: a == undefined ? start : a,
      end: b == undefined ? end : b,
    };

    backend.load_payout(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setPayOut(r.payout);
        setPayOutSearch(r.payout);
        setTotal(r.amount);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const sort = (s) => {
    setKey(!key);

    let data;

    if (s == "ClientUp") {
      data = payoutsearch.sort((a, b) =>
        a.client_username > b.client_username ? 1 : -1
      );
    } else if (s == "ClientDown") {
      data = payoutsearch.sort((a, b) =>
        a.client_username < b.client_username ? 1 : -1
      );
    } else if (s == "SymbolUp") {
      data = payoutsearch.sort((a, b) => (a.symbol > b.symbol ? 1 : -1));
    } else if (s == "SymbolDown") {
      data = payoutsearch.sort((a, b) => (a.symbol < b.symbol ? 1 : -1));
    } else if (s == "BuySellUp") {
      data = payoutsearch.sort((a, b) => (a.buy_sell > b.buy_sell ? 1 : -1));
    } else if (s == "BuySellDown") {
      data = payoutsearch.sort((a, b) => (a.buy_sell < b.buy_sell ? 1 : -1));
    } else if (s == "QtyUp") {
      data = payoutsearch.sort((a, b) => (a.qty > b.qty ? 1 : -1));
    } else if (s == "QtyDown") {
      data = payoutsearch.sort((a, b) => (a.qty < b.qty ? 1 : -1));
    } else if (s == "RateUp") {
      data = payoutsearch.sort((a, b) => (a.rate > b.rate ? 1 : -1));
    } else if (s == "RateDown") {
      data = payoutsearch.sort((a, b) => (a.rate < b.rate ? 1 : -1));
    } else if (s == "LimitUp") {
      data = payoutsearch.sort((a, b) => (a.high_mid > b.high_mid ? 1 : -1));
    } else if (s == "LimitDown") {
      data = payoutsearch.sort((a, b) => (a.high_mid < b.high_mid ? 1 : -1));
    } else if (s == "TypeUp") {
      data = payoutsearch.sort((a, b) => (a.limit_sl > b.limit_sl ? 1 : -1));
    } else if (s == "TypeDown") {
      data = payoutsearch.sort((a, b) => (a.limit_sl < b.limit_sl ? 1 : -1));
    } else if (s == "MsgUp") {
      data = payoutsearch.sort((a, b) => (a.msg > b.msg ? 1 : -1));
    } else if (s == "MsgDown") {
      data = payoutsearch.sort((a, b) => (a.msg < b.msg ? 1 : -1));
    } else if (s == "DateUp") {
      data = payoutsearch.sort((a, b) =>
        a.date_created > b.date_created ? 1 : -1
      );
    } else if (s == "DateDown") {
      data = payoutsearch.sort((a, b) =>
        a.date_created < b.date_created ? 1 : -1
      );
    } else {
      setPayOut([]);
    }

    setPayOut(data);
  };

  return (
    <div
      style={{
        display: "flex",
        height: "82vh",
        flexDirection: "column",
      }}
    >
      <TopCard total={total} start={start} end={end} filter={loadPayOut} />
      <div style={{ backgroundColor: Dark.background }}>
        <Menu sort={sort} />
        <div className="scroll" style={{ height: "68vh", overflowY: "scroll" }}>
          {loading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading type="spin" height={35} width={35} color={Dark.text} />
            </div>
          ) : payout.length > 0 ? (
            payout.map((i, t) => {
              return <PayOutCard item={i} index={t} load={loadPayOut} />;
            })
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textTransform: "uppercase",
              }}
            >
              <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
                {" "}
                Nothing here
              </div>
              <div style={{ color: Dark.text }}>No PayOut Found.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

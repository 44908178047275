import React, { useState } from "react";
import FavoriteCard from "./FavoriteCard";
import { Dark } from "../../../../Theme/Theme";
export default function Favorite(props) {
  const height = window.innerHeight;

  const [favorite] = useState(localStorage.getItem("favorite"));

  return (
    <div style={{ height: height / 1.4 }}>
      {favorite != null ? (
        JSON.parse(favorite).length > 0 ? (
          <div
            className="scroll"
            style={{
              height: "100%",
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              overflowY: "scroll",
            }}
          >
            {JSON.parse(favorite).map((i, t) => {
              return <FavoriteCard item={i} />;
            })}
          </div>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
              {" "}
              Nothing here
            </div>
            <div style={{ color: Dark.text }}>
              Use the search bar to add instruments.
            </div>
          </div>
        )
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
            {" "}
            Nothing here
          </div>
          <div style={{ color: Dark.text }}>
            Use the search bar to add instruments.
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import Client from "./Client/Client";
import Edit from "./Edit/Edit";
import Ledger from "./Ledger/Ledger";
import LoginLog from "./LoginLog/LoginLog";

export default function BrokerList(props) {
  const broker = props.broker;

  const [type, setType] = useState(0);

  return (
    <div
      key={broker.id}
      style={{
        height: "78vh",
        backgroundColor: Dark.background,
        borderRadius: 10,
      }}
    >
      <div
        style={{
          height: 65,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          paddingLeft: 10,
          paddingRight: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderStyle: "solid",
          borderWidth: 0,
          borderBottomWidth: 2,
          borderBottomColor: Dark.primary,
        }}
      >
        <div style={{ color: Dark.text, flex: 1 }}>
          <div style={{ fontSize: 18 }}>
            Username : {broker.broker_name}
          </div>
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 2.5,
            display: "flex",
            height: "100%",
          }}
        >
          <div
            onClick={() => setType(0)}
            style={{
              flex: 1,
              fontSize: 14,
              color: type == 0 ? Dark.primary : Dark.text,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            CLIENT
          </div>
          <div
            onClick={() => setType(1)}
            style={{
              flex: 1,
              fontSize: 14,
              color: type == 1 ? Dark.primary : Dark.text,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            EDIT
          </div>
          <div
            onClick={() => setType(2)}
            style={{
              flex: 1,
              fontSize: 14,
              color: type == 2 ? Dark.primary : Dark.text,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            LEDGER
          </div>

          <div
            onClick={() => setType(3)}
            style={{
              flex: 1,
              fontSize: 14,
              color: type == 3 ? Dark.primary : Dark.text,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            LOGIN LOG
          </div>
        </div>
      </div>

      <div style={{ height: "90%" }}>
        {type == 0 ? <Client broker={broker} reload={props.reload} /> : null}
        {type == 1 ? <Edit broker={broker} reload={props.reload} /> : null}
        {type == 2 ? <Ledger broker={broker} reload={props.reload} /> : null}
        {type == 3 ? <LoginLog broker={broker} reload={props.reload} /> : null}
      </div>
    </div>
  );
}
